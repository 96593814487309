/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from "react";

import {
  Box,
  Flex,
  Text,
  Link,
  Divider,
  SecondaryButton,
  PrimaryButton,
  TextInput,
} from "flicket-ui";

import { useRouter } from "next/router";
import { Footer, SlateRenderer, Icon, PageContainer } from "~components";
import { formatDate, handlePromise } from "~lib/helpers";
import { sdk } from "~lib";
import { useOrganization } from "~hooks";
import { EventTitle } from "../events.Title";
import { EventQuery, LandingPage } from "~graphql/sdk";
import useDescriptionText from "~hooks/useDescriptionText";
import Image from "~components/common/Image";

export type DesktopDetailProps = EventQuery["event"] & {
  showSeatsCTA: boolean;
  isSinglePage: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const Desktop = ({
  title,
  id,
  description,
  startDate,
  endDate,
  venue,
  logo,
  showSeatsCTA,
  accessibility,
  setIsOpen,
  termsAndConditions,
  isSinglePage,
  backgroundImage,
  backgroundImageFullPage,
  descriptionImage,
  enableReleaseCodes,
  enableWaitlist,
  season,
}: DesktopDetailProps) => {
  const router = useRouter();
  const { organization } = useOrganization();
  const isUnlistedRelease = router.query.slug ? true : false;
  const cartRecovery = router.query.cartRecovery;
  const descriptionText = useDescriptionText(description);

  const releaseUrl = (() => {
    if (isUnlistedRelease) {
      if (cartRecovery) {
        return `/${id}/releases/${router.query.slug}?cartRecovery=true`;
      } else {
        return `/${id}/releases/${router.query.slug}`;
      }
    } else {
      if (cartRecovery) {
        return `/events/${id}/reservation?cartRecovery=true`;
      } else {
        return `/events/${id}/reservation`;
      }
    }
  })();

  const gotToRelease = async () => {
    const inputElement = document.getElementById(
      "reservationInputD"
    ) as HTMLInputElement;

    const code = inputElement.value.trim();
    if (!code) {
      return;
    }

    const { data: release } = await handlePromise(async () =>
      sdk({ orgId: organization?.id }).validateReleasePasswordOrRelease({
        eventId: id,
        password: code,
      })
    );

    void router.push({
      pathname: `/${id}/releases/${encodeURIComponent(
        release?.validateReleasePasswordOrRelease?.slug
      )}`,
      query: {
        code: release?.validateReleasePasswordOrRelease?.password
          ? undefined
          : code,
        ...(cartRecovery ? { cartRecovery: "true" } : {}),
      },
    });
  };

  return (
    <PageContainer
      backgroundImage={backgroundImage}
      backgroundImageFullPage={backgroundImageFullPage}
      bannerImage={logo}
      titleText={title}
      title={
        <EventTitle
          isSinglePage={isSinglePage}
          title={title}
          startDate={startDate}
          endDate={endDate}
          city={venue?.address?.city}
          venueName={venue?.name}
          tourId={
            organization.landingPage === LandingPage.Tours && season
              ? season.id
              : null
          }
        />
      }
      footer={<Footer />}
    >
      <Box mx={"auto" as any} maxW="800px">
        {showSeatsCTA ? (
          <>
            <Flex mt={logo ? 5 : 1} justifyContent="center">
              <PrimaryButton
                fontSize={4}
                w="358px"
                boxShadow="button"
                to={releaseUrl}
              >
                Get Tickets
              </PrimaryButton>
            </Flex>
          </>
        ) : (
          !isUnlistedRelease &&
          enableWaitlist && (
            <Flex mt={logo ? 5 : 1} justifyContent="center">
              <Link to={`/events/${id}/waitlist`}>
                <PrimaryButton fontSize={4} w="358px" boxShadow="button">
                  Join the waitlist
                </PrimaryButton>
              </Link>
            </Flex>
          )
        )}
        {!isUnlistedRelease && enableReleaseCodes && (
          <>
            <Flex mt={logo ? 5 : 1} justifyContent="center">
              <TextInput
                name="reservationInputD"
                id="reservationInputD"
                width="358px"
                placeholder={
                  organization?.id === "58106ce0-e1cd-47cb-9c59-0d0192ca6df8"
                    ? "FOR THE LOVE OF THE CLUB CODE"
                    : organization?.id ===
                      "45339f71-c0bb-41a9-b642-7d30f1321a84"
                    ? "Club/School/Sponsor Code"
                    : "Release Code"
                }
              />
            </Flex>

            <Flex mt={logo ? 5 : 1} justifyContent="center">
              <PrimaryButton
                fontSize={4}
                w="358px"
                boxShadow="button"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={gotToRelease}
              >
                {organization?.id === "58106ce0-e1cd-47cb-9c59-0d0192ca6df8"
                  ? "FOR THE LOVE OF THE CLUB"
                  : organization?.id === "45339f71-c0bb-41a9-b642-7d30f1321a84"
                  ? "Club/School/Sponsor Code"
                  : "Unlock Allocated Tickets"}
              </PrimaryButton>
            </Flex>
          </>
        )}
        <Divider mt={5} mb={3} />
        <Flex alignItems="center" justifyContent="space-between" pb={3}>
          <Flex>
            <Flex>
              <Icon icon="date-range" fontSize={6} color="N800" />
              <Text fontWeight="extraBold" fontSize={4} ml={1}>
                {endDate &&
                formatDate(startDate, "dd") !== formatDate(endDate, "dd")
                  ? `${formatDate(startDate, "dd LLL")} - ${formatDate(
                      endDate,
                      "dd LLL yyyy"
                    )}`
                  : formatDate(startDate, "EEEE d MMMM, p")}
              </Text>
            </Flex>
            <Link
              display="flex"
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${venue?.location?.lat},${venue?.location?.lng}`}
              ml={5}
            >
              <Icon icon="map" fontSize={6} color="N800" />
              <Text fontWeight="extraBold" fontSize={4} ml={1}>
                {`${venue?.name}, ${
                  venue?.address?.city ? venue?.address?.city : null
                }`}
              </Text>
            </Link>
          </Flex>
          {accessibility && (
            <SecondaryButton onClick={() => setIsOpen(true)} mr={2}>
              Accessibility Info
            </SecondaryButton>
          )}
        </Flex>
        <Divider />
        <Box mt={5} mb={(termsAndConditions || showSeatsCTA) && 5}>
          <SlateRenderer value={description} mb={3} />
          {descriptionImage && (
            <Image image={descriptionImage} alt={descriptionText} />
          )}
        </Box>
        {(termsAndConditions || showSeatsCTA) && (
          <Divider display={{ _: "none", md: "flex" }} mb={3} />
        )}
        <Flex flexDir={{ _: "column", md: "row" }} justifyContent="flex-end">
          {termsAndConditions && (
            <SecondaryButton
              to={`/events/${id}/terms-and-conditions`}
              minH="48px"
              display="flex"
              w={{ _: "100%", md: "auto" }}
            >
              Terms &amp; Conditions
            </SecondaryButton>
          )}
          {showSeatsCTA && (
            <>
              <Divider my={3} display={{ _: "flex", md: "none" }} />
              <PrimaryButton
                minH="48px"
                display="flex"
                w={{ _: "100%", md: "auto" }}
                ml={{ _: 0, md: 2 }}
                to={releaseUrl}
              >
                Get Tickets
              </PrimaryButton>
            </>
          )}
        </Flex>
      </Box>
    </PageContainer>
  );
};
